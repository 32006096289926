<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style'; 

  .b-table.table>thead>tr>th[aria-sort][aria-sort="ascending"]::after,
  .b-table.table>tfoot>tr>th[aria-sort][aria-sort="ascending"]::after {
      opacity: 1;
      content: "\2191" !important;
  }

  .table.b-table>thead>tr>th[aria-sort][aria-sort="descending"]::after,
  .table.b-table>tfoot>tr>th[aria-sort][aria-sort="descending"]::after {
      opacity: 1;
      content: "\2193" !important;
  }

</style>
