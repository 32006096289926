import 'core-js/stable'
import 'regenerator-runtime/runtime';
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import Axios from 'axios'
import IdleVue from 'idle-vue'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueGoodTablePlugin from 'vue-good-table'
import VueSweetalert2 from 'vue-sweetalert2'

// import the styles
import 'vue-good-table/dist/vue-good-table.css'
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueGoodTablePlugin);
Vue.use(VueSweetalert2);

const eventsHub = new Vue();

Axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
  //console.log(error.response.data)
  if (error.response.status === 403) {    
    store.dispatch('logout');
    localStorage.removeItem('isAuthenticated');
    router.push('/auth/login').catch(()=>{});
  }
  return Promise.reject(error)
})

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 2400000, // before 20 minutes, after 40 minutes
  startAtIdle: false
});

Vue.config.performance = true
Vue.use(VueExcelXlsx);
Vue.use(CoreuiVue)
Vue.use(BootstrapVue)
Vue.prototype.$log = console.log.bind(console)
//Vue.config.productionTip = false

Axios.defaults.headers.common['Authorization'] = `${store.state.token}`;
Axios.defaults.headers.post['Content-Type'] ='application/json';
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
