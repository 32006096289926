import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')
const ComingSoon = () => import('@/views/pages/comingsoon')
var cspage = import('@/views/pages/comingsoon');

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const PasswordExpiry = () => import('@/views/pages/Password')
const Otp = () => import('@/views/pages/Otp')
const Register = () => import('@/views/pages/Register')

// Users
const Users = () => import('@/views/users/users_v2')
const Detail = () => import('@/views/users/detail')
const EarmarkUser = () => import('@/views/users/details/editmark')
const KYCBrowse = () => import('@/views/users/kyc/browse')
const KYCImage = () => import('@/views/users/kyc/image')
const KYCImgCreate = () => import('@/views/users/kyc/create')
const KYCImgEdit = () => import('@/views/users/kyc/edit')
const ChangePasswd = () => import('@/views/users/changepasswd')
const DeactReqList = () => import('@/views/users/deactivate')

//Transactions
const UserTransaction = () => import('@/views/transactions/UserTransaction')
const AdminTransaction = () => import('@/views/transactions/AdminTransaction')
const CreditUser = () => import('@/views/transactions/CreditUser_v2')
const DebitUser = () => import('@/views/transactions/DebitUser_v2')
const ErrorRemittPending  = () => import('@/views/remittance/browse_v2')
const GoodRemittPending  = () => import('@/views/remittance/browse')
const RemittPending  = () => import('@/views/remittance/browse_v3')
const PTCPending  = () => import('@/views/transactions/ptc/browse')
const QRISPending = () => import('@/views/transactions/qris/browse');
const AccountBalance = () => import('@/views/transactions/weChat/account_balance');
const WeChatPending = () => import('@/views/transactions/weChat/browse');
const DTODetail = () => import('@/views/transactions/DTODetail');

const TransactionRequests = () => import('@/views/transactions/requests/browse');

const TransactionRequestsAll = () => import('@/views/transactions/requests/all');

const TopupDetails = () => import('@/views/transactions/TopupDetails');

const EarmarkTopUpConfig = () => import('@/views/transactions/EarmarkTopupConfig.vue');

//Rollback
const BankPNRollback = () => cspage; //import('@/views/rollback/pnrbbank')
const BankPNComplete = () => cspage; //import('@/views/rollback/pnrbbankcompl')
const eWalletRollback = () => import('@/views/rollback/p2prollback')
const BankPNPending  = () => import('@/views/rollback/browse')

//Wallets
const WalletGroups = () => import('@/views/wallets-v2/browse')
const CreateWalletGroup = () => import('@/views/wallets-v2/create')
const DetailWalletGroup = () => import('@/views/wallets-v2/detail')
const AssignGroups = () => import('@/views/wallets/assign')

//Promotions
const ListOfPromotion  = () => import('@/views/promotions/browse')
const CreatePromotion  = () => import('@/views/promotions/create')
const DetailPromotion  = () => import('@/views/promotions/detail')
const UploadPromoImage = () => import('@/views/promotions/image')
const DefaultPromoImage = () => import('@/views/promotions/promo_default_img')
const Refferal = () => import('@/views/promotions/referral')

//Reward
const ListOfRewardCategory  = () => import('@/views/reward/category/browse')
const CreateRewardCategory  = () => import('@/views/reward/category/create')
const DetailRewardCategory  = () => import('@/views/reward/category/detail')
const ImageRewardCategory   = () => import('@/views/reward/category/image')
const OrderRewardCategory   = () => import('@/views/reward/category/vieworder')

const ListOfRewardCatalog  = () => import('@/views/reward/catalog/browse')
const CreateRewardCatalog  = () => import('@/views/reward/catalog/create')
const DetailRewardCatalog  = () => import('@/views/reward/catalog/detail')
const ImageRewardCatalog  = () => import('@/views/reward/catalog/image')

//Advertisement
const ListOfAdvertise  = () => import('@/views/advertisement/browse')
const CreateAdvertise  = () => import('@/views/advertisement/create')
const EditAdvertise  = () => import('@/views/advertisement/edit')
const ImageAdvertise  = () => import('@/views/advertisement/image')

//Merchant
const ListOfMerchant = () => import('@/views/merchant/browse')
const CreateMerchant = () => import('@/views/merchant/create')
const DetailMerchant = () => import('@/views/merchant/detail')
const DefaultMerchantImage = () => import('@/views/merchant/merchant_default_img')

//System Configuration
const Config = () => import('@/views/system/config')
const ConfigDetail = () => import('@/views/system/detail')

//User Level / Tiers
const ListOfTier = () => import('@/views/users/tier/browse')
const EditTier = () => import('@/views/users/tier/edit')
const ImageTier = () => import('@/views/users/tier/image')

//Admin User Management
const ListOfAdmin  = () => import('@/views/admin/browse')
const ListOfGroups = () => import('@/views/admin/groups')
const ListOfRoles  = () => import('@/views/admin/roles')
const ViewRole     = () => import('@/views/admin/viewroles')
const CreateAdmin  = () => import('@/views/admin/create')
const EditAdmin    = () => import('@/views/admin/update')
const ReplacePasswd = () => import('@/views/admin/replacepasswd')

//Accounts
const MainAccount  = () => import('@/views/accounts/main')

//Management Fee
const ConfigFee  = () => import('@/views/system/fee/browse')
const AddFee   = () => import('@/views/system/fee/add')
const EditFee  = () => import('@/views/system/fee/edit')
const SingleFactor = () => import('@/views/system/ad-hoc/browse_single')
const AddSingleFactor = () => import('@/views/system/ad-hoc/create_single')
const EditSingleFactor = () => import('@/views/system/ad-hoc/edit_single')
const CombFactor = () => import('@/views/system/ad-hoc/browse_comb')
const AddCombFactor = () => import('@/views/system/ad-hoc/create_comb')
const EditCombFactor = () => import('@/views/system/ad-hoc/edit_comb')

//Prepaid Topup
const ActiveCards = () => import('@/views/topup/prepaid/activecards')
const AddNewCard  = () => import('@/views/topup/prepaid/addcard')
const Consumed    = () => import('@/views/topup/prepaid/consumed')

//Notifications
const AddNewNotif   = () =>  import('@/views/notifications/create')
const NotifCategory = () =>  import('@/views/notifications/category')
const CreateNotifCategory = () =>  import('@/views/notifications/create_notif_cat')
const EditNotifCategory = () =>  import('@/views/notifications/edit_notif_cat')
const ListOfTemplate = () =>  import('@/views/notifications/template/browse')
const AddNewTemplate = () =>  import('@/views/notifications/template/create')
const EditTemplate = () =>  import('@/views/notifications/template/edit')

//Manage User Groups
const UserGroups  = () => import('@/views/users/groups/browse')
const NewUserGroups   = () => import('@/views/users/groups/create-v3')
const EditUserGroup   = () => import('@/views/users/groups/edit')
const DetailUserGroup = () => import('@/views/users/groups/detail-v3')

//Report
const Walletbalance  = () => import('@/views/reports/walletbalance')
const Walletbalancedaily = () => import('@/views/reports/walletbalancelist')
const PromoUsage     = () => import('@/views/reports/promousage')
const DebitCredit    = () => import('@/views/reports/debitcredit_v2')
const BalanceByTopup = () => import('@/views/reports/balancebytopup')
const TotalFeesColected = () => import('@/views/reports/totalfeescollected')
const AccLegsEntries = () => import('@/views/reports/acc_legs_entries')
const MCoins = () => import('@/views/reports/mcoins')
const UserReport = () => import('@/views/reports/user_report')
const PTCLogs = () => import('@/views/transactions/ptc/logs')
const TRSReport = () => import('@/views/reports/trsreport')

//Greeting Card
const GCard = () => import('@/views/system/greetingcard/browse')
const CreateGCard = () => import('@/views/system/greetingcard/create')
const DetailGCard = () => import('@/views/system/greetingcard/detail')
const UploadGCardImage = () => import('@/views/system/greetingcard/upload')

//Background Image
const BGImage = () => import('@/views/system/bgimage/browse')
const CreateBGImg = () => import('@/views/system/bgimage/create')
const DetailBGImg = () => import('@/views/system/bgimage/detail')
const UploadBGImg = () => import('@/views/system/bgimage/upload')

//Referral flow images
const ReferralImg  = () => import('@/views/system/referralimage/browse')
const CreateRefImg = () => import('@/views/system/referralimage/create')

//DBS-MCA Banner
const DBSMCABanner = () => import('@/views/system/dbs-mca/banner')

//Fraud Sentinel
const FraudTrType = () => import('@/views/fraud/transaction_type/browse')
const FraudCreateTrType = () => import('@/views/fraud/transaction_type/create')
const FraudEditTrType = () => import('@/views/fraud/transaction_type/edit')
const FraudAllRule = () => import('@/views/fraud/browse_all_rule')
const FraudClientTransact = () => import('@/views/fraud/browse_client_transact')
const FraudTrRule = () => import('@/views/fraud/rule/browse')
const FraudCreateTrRule = () => import('@/views/fraud/rule/create')
const FraudEditTrRule = () => import('@/views/fraud/rule/edit')
const FraudInstruction = () => import('@/views/fraud/instructions')

const WalletImageTier = () => import('@/views/wallets/imagetier')

Vue.use(Router)

function guardMyroute(to, from, next)
{
   //var isAuthenticated= false;
   //if(store.getters.isLoggedIn){  isAuthenticated = true; }

   if(localStorage.getItem('isAuthenticated'))
   {
    next(); // allow to enter route
   }
   else
   {
    next('/auth/login'); // go to '/login';
   }
}

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          beforeEnter : guardMyroute,
          component: Dashboard
        },
        {
          path: 'changepasswd',
          beforeEnter : guardMyroute,
          name: 'ChangePasswd',
          meta: {
            label: 'Change Password'
          },
          component: ChangePasswd
        },
        {
          path: 'accounts',
          beforeEnter : guardMyroute,
          name: 'accounts',
          meta: {
            label: 'Accounts'
          },
          component: MainAccount
        },
        {
          path: 'notif',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Notification'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/notif',
              name: 'Send',
              component: AddNewNotif
            },
          ]
        },
        {
          path: 'notif/template',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Notification Template'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/notif/template',
              name: 'Template',
              component: ListOfTemplate
            },
            {
              path: '/notif/template/create',
              name: 'Create',
              component: AddNewTemplate
            },
            {
              path: '/notif/template/edit/:id',
              name: 'Create',
              component: EditTemplate
            },
          ]
        },
        {
          path: 'notif/category',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Notification Category'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/notif/category',
              name: 'NotifCategory',
              meta: {
                label: 'Category'
              },
              component: NotifCategory
            },
            {
              path: '/notif/create/category',
              name: 'CreateNotifCategory',
              meta: {
                label: 'Create'
              },
              component: CreateNotifCategory
            },
            {
              path: '/notif/edit/category/:code',
              name: 'EditNotifCategory',
              meta: {
                label: 'Edit'
              },
              component: EditNotifCategory
            }
          ]
        },
        {
          path: 'ugroups',
          beforeEnter : guardMyroute,
          meta: {
            label: 'User Groups'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/ugroups',
              name: 'Management',
              component: UserGroups
            },
            {
              path: '/ugroups/create',
              name: 'NewUserGroup',
              meta: {
                label: 'New Group'
              },
              component: NewUserGroups
            },
            {
              path: '/ugroups/detail/:id',
              name: 'DetailUserGroup',
              meta: {
                label: 'Detail'
              },
              component: DetailUserGroup
            },
            {
              path: '/ugroups/edit/:id',
              name: 'EditUserGroup',
              meta: {
                label: 'Edit'
              },
              component: EditUserGroup
            }
          ]
        },
        {
          path: 'tier',
          beforeEnter : guardMyroute,
          meta: {
            label: 'User Tier'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/tier',
              name: 'List',
              component: ListOfTier
            },
            {
              path: '/tier/edit/:id',
              name: 'EditUserTier',
              meta: {
                label: 'Edit'
              },
              component: EditTier
            },
            {
              path: '/tier/image/:id',
              name: 'ImageUserTier',
              meta: {
                label: 'Image'
              },
              component: ImageTier
            }
          ]
        },
        {
          path: 'kyc',
          beforeEnter : guardMyroute,
          meta: {
            label: 'KYC'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/kyc',
              name: 'List',
              component: KYCBrowse
            },
            {
              path: '/kyc/image/create',
              name: 'Create',
              component: KYCImgCreate
            },
            {
              path: '/kyc/image/edit/:id',
              name: 'KycEdit',
              meta: {
                label: 'Edit'
              },
              component: KYCImgEdit
            },
            {
              path: '/kyc/image/:id',
              name: 'KYCImage',
              meta: {
                label: 'Sample Image'
              },
              component: KYCImage
            }
          ]
        },
        {
          path: 'tier',
          beforeEnter : guardMyroute,
          meta: {
            label: 'User Tier'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/tier',
              name: 'List',
              component: ListOfTier
            },
            {
              path: '/tier/edit/:id',
              name: 'EditUserTier',
              meta: {
                label: 'Edit'
              },
              component: EditTier
            },
            {
              path: '/tier/image/:id',
              name: 'ImageUserTier',
              meta: {
                label: 'Image'
              },
              component: ImageTier
            }
          ]
        },
        {
          path: 'kyc',
          beforeEnter : guardMyroute,
          meta: {
            label: 'KYC'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/kyc',
              name: 'List',
              component: KYCBrowse
            },
            {
              path: '/kyc/image/create',
              name: 'Create',
              component: KYCImgCreate
            },
            {
              path: '/kyc/image/edit/:id',
              name: 'KycEdit',
              meta: {
                label: 'Edit'
              },
              component: KYCImgEdit
            },
            {
              path: '/kyc/image/:id',
              name: 'KYCImage',
              meta: {
                label: 'Sample Image'
              },
              component: KYCImage
            }
          ]
        },
        {
          path: 'config',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Config'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/config',
              name: 'System',
              component: Config
            },
            {
              path: '/config/detail/:id',
              name: 'ConfigDetail',
              meta: {
                label: 'Detail'
              },
              component: ConfigDetail
            }
          ]
        },
        {
          path: '/fraudtransactiontype',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Transaction Type'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/fraudtransactiontype',
              name: 'fraudtrstype',
              meta: {
                label: 'List'
              },
              component: FraudTrType
            },
            {
              path: '/createtrstype',
              name: 'fraudcreatetrtype',
              meta: {
                label: 'Create'
              },
              component: FraudCreateTrType
            },
            {
              path: '/edittrstype/:id',
              name: 'fraudedittrtype',
              meta: {
                label: 'Edit'
              },
              component: FraudEditTrType
            },
          ]
        },
        {
          path: 'presetrules',
          beforeEnter : guardMyroute,
          name: 'presetrules',
          meta: {
            label: 'Preset Rules'
          },
          component: FraudAllRule
        },
        {
          path: 'fraudtrsmonitor',
          beforeEnter : guardMyroute,
          name: 'fraudtrsmonitor',
          meta: {
            label: 'Monitoring'
          },
          component: FraudClientTransact
        },
        {
          path: '/typerulesmap',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Type - Rules Mapping'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/typerulesmap',
              name: 'typerulesmap',
              meta: {
                label: 'List'
              },
              component: FraudTrRule
            },
            {
              path: '/createtyperulesmap/:code',
              name: 'createtyperulesmap',
              meta: {
                label: 'Create'
              },
              component: FraudCreateTrRule
            },
            {
              path: '/edittyperulesmap/:id',
              name: 'edittyperulesmap',
              meta: {
                label: 'Edit'
              },
              component: FraudEditTrRule
            },
          ]
        },
        {
          path: 'fraudinstructions',
          beforeEnter : guardMyroute,
          name: 'fraudinstructions',
          meta: {
            label: 'Instructions'
          },
          component: FraudInstruction
        },
        {
          path: 'settings/fee',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Settings'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/settings/fee',
              name: 'Fee',
              component: ConfigFee
            },
            {
              path: '/settings/fee/add',
              name: 'AddFee',
              meta: {
                label: 'Add Fee'
              },
              component: AddFee
            },
            {
              path: '/settings/fee/:id',
              name: 'EditFee',
              meta: {
                label: 'Edit Fee'
              },
              component: EditFee
            },
            {
              path: '/dtoupload',
              name: 'dto_detail',
              meta: {
                label: 'DTO Operator Image Upload'
              },
              component: DTODetail
            },
            {
              path: '/earmarktopupconfig',
              name: 'eamark_topup_config',
              meta: {
                label: 'Earmark Top-up Config'
              },
              component: EarmarkTopUpConfig
            },
          ]
        },
        {
          path: 'factor',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Factor'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/factor',
              name: 'Card Fees',
              component: SingleFactor
            },
            {
              path: '/factor/single',
              name: 'New Card Fee',
              component: AddSingleFactor
            },
            {
              path: '/factor/edit/:id',
              name: 'Edit Card Fee',
              component: EditSingleFactor
            },
          ]
        },
        {
          path: 'cfactor',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Factor'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/cfactor',
              name: 'Combination',
              component: CombFactor
            },
            {
              path: '/cfactor/comb',
              name: 'AddCombination',
              meta: {
                label: 'Add Combination'
              },
              component: AddCombFactor
            },
            {
              path: '/cfactor/edit/:id',
              name: 'EditCombination',
              meta: {
                label: 'Edit Combination'
              },
              component: EditCombFactor
            },
          ]
        },
        {
          path: 'transact/user',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Transactions'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/transact/user',
              name: 'By User',
              component: UserTransaction
            },
            {
              path: '/transact/admin',
              name: 'By Admin',
              component: AdminTransaction
            },
            {
              path: '/transact/credit',
              name: 'Credit User',
              component: CreditUser
            },
            {
              path: '/transact/debit',
              name: 'Debit User',
              component: DebitUser
            },
            {
              path: '/transact/debit',
              name: 'Debit User',
              component: DebitUser
            },
            {
              path: '/transact/bank/rollback',
              name: 'bank_rollback',
              meta: {
                label: 'Bank Pay Now Rollback'
              },
              component: BankPNRollback
            },
            {
              path: '/transact/bank/complete',
              name: 'bank_complete',
              meta: {
                label: 'Bank Pay Now Complete'
              },
              component: BankPNComplete
            },
            {
              path: '/transact/bank/pending',
              name: 'bank_pn_pending',
              meta: {
                label: 'Bank Pay Now Pending'
              },
              component: BankPNPending
            },
            {
              path: '/transact/ewallet/rollback',
              name: 'ewallet_rollback',
              meta: {
                label: 'E-Wallet Rollback'
              },
              component: eWalletRollback
            },
            {
              path: '/transact/remittance/error',
              name: 'error_remitt_pending',
              meta: {
                label: 'Error Remittance'
              },
              component: ErrorRemittPending
            },
            {
              path: '/transact/remittance/good',
              name: 'good_remitt_pending',
              meta: {
                label: 'Good Remittance'
              },
              component: GoodRemittPending
            },
            {
              path: '/transact/remittance/pending',
              name: 'remitt_pending',
              meta: {
                label: 'Remittance Pending'
              },
              component: RemittPending
            },
            {
              path: '/transact/ptcpending',
              name: 'ptc_pending',
              meta: {
                label: 'PTC Pending'
              },
              component: PTCPending
            },
            {
              path: '/transact/qris',
              name: 'qris_pending',
              meta: {
                label: 'QRIS Pending'
              },
              component: QRISPending
            },
            {
              path: '/transact/account_balance',
              name: 'account_balance',
              meta: {
                label: 'WeChat MG Account Balance'
              },
              component: AccountBalance
            },
            {
              path: '/transact/wechat',
              name: 'wechat_pending',
              meta: {
                label: 'WeChat Pending'
              },
              component: WeChatPending
            },
            {
              path: '/transact/requests',
              name: 'requests',
              meta: {
                label: 'Transaction System Approval'
              },
              component: TransactionRequests
            },
            {
              path: '/transact/all-requests',
              name: 'transactionrequestsall',
              meta: {
                label: 'Transaction System'
              },
              component: TransactionRequestsAll
            },
            {
              path: '/transact/topup-details',
              name: 'topupdetails',
              meta: {
                label: 'Top-up Detail'
              },
              component: TopupDetails
            },
          ]
        },
        {
          path: '/eWallet/users',
          beforeEnter : guardMyroute,
          meta: {
            label: 'eWallet'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/eWallet/users',
              name: 'Users',
              component: Users
            },
            {
              path: '/eWallet/details/:id/:dbid',
              name: 'Details',
              meta: {
                label: 'User Details'
              },
              component: Detail
            },
            {
              path: '/eWallet/details/:id/:dbid/earmark/:walletId/:purpose',
              name: 'EarmarkManage',
              meta: {
                label: 'User Details / Earmark Manage'
              },
              component: EarmarkUser
            },
            {
              path: '/eWallet/deactivate',
              name: 'DeactReqList',
              meta: {
                label: 'Deactivate User Request'
              },
              component: DeactReqList
            }

          ]
        },
        {
          path: '/wallet',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Wallets'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/wallet',
              name: 'Wallet Groups',
              component: WalletGroups
            },
            {
              path: '/wallet/groups',
              name: 'Groups',
              component: CreateWalletGroup
            },
            {
              path: '/wallet/tier/image',
              name: 'Default Wallet-Card Image',
              meta: {
                label: 'Default Wallet-Card Image'
              },
              component: WalletImageTier
            },
            {
              path: '/wallet/detail/:id',
              name: 'DetailGroup',
              component: DetailWalletGroup
            },
            {
              path: '/wallet/assign',
              name: 'Assign Groups',
              component: AssignGroups
            }
          ]
        },
        {
          path: '/promo',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Promotions'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/promo',
              name: 'List',
              component: ListOfPromotion
            },
            {
              path: '/promo/create',
              name: 'Create',
              component: CreatePromotion
            },
            {
              path: '/promo/refferal',
              name: 'Refferal',
              component: Refferal
            },
            {
              path: '/promo/details/:id',
              name: 'PromoDetails',
              meta: {
                label: 'Details'
              },
              component: DetailPromotion
            },
            {
              path: '/promo/upload/:code',
              name: 'PromoUpload',
              meta: {
                label: 'Upload Image'
              },
              component: UploadPromoImage
            },
            {
              path: '/promo/img/default',
              name: 'PromoDefaultImg',
              meta: {
                label: 'Default Image'
              },
              component: DefaultPromoImage
            }
          ]
        },
        {
          path: '/reward/category',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Reward Category'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/reward/category',
              name: 'List',
              component: ListOfRewardCategory
            },
            {
              path: '/reward/category/create',
              name: 'Create',
              component: CreateRewardCategory
            },
            {
              path: '/reward/category/upload/:id',
              name: 'Upload Image',
              component: ImageRewardCategory
            },
            {
              path: '/reward/category/:id',
              name: 'Edit',
              component: DetailRewardCategory
            },
            {
              path: '/reward/category/view/order',
              name: 'View Order',
              component: OrderRewardCategory
            },
          ]
        },
        {
          path: '/reward/catalog',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Reward Catalog'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/reward/catalog',
              name: 'List',
              component: ListOfRewardCatalog
            },
            {
              path: '/reward/catalog/create',
              name: 'Create',
              component: CreateRewardCatalog
            },
            {
              path: '/reward/catalog/:id',
              name: 'Edit',
              component: DetailRewardCatalog
            },
            {
              path: '/reward/catalog/upload/:id',
              name: 'Upload Image',
              component: ImageRewardCatalog
            },
          ]
        },
        {
          path: '/greetingcard',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Greeting Card'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/greetingcard',
              name: 'List',
              component: GCard
            },
            {
              path: '/greetingcard/create',
              name: 'Create',
              component: CreateGCard
            },
            {
              path: '/greetingcard/detail',
              name: 'GCardDetail',
              meta: {
                label: 'Detail'
              },
              component: DetailGCard
            },
            {
              path: '/greetingcard/upload/:id',
              name: 'GCardUpload',
              props: true,
              meta: {
                label: 'Upload Image'
              },
              component: UploadGCardImage
            },
          ]
        },
        {
          path: '/bgimage',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Background Image'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/bgimage',
              name: 'List',
              component: BGImage
            },
            {
              path: '/bgimage/create',
              name: 'Create',
              component: CreateBGImg
            },
            {
              path: '/bgimage/detail',
              name: 'BGImgDetail',
              meta: {
                label: 'Detail'
              },
              component: DetailBGImg
            },
            {
              path: '/bgimage/upload/:id',
              name: 'BGImgUpload',
              props: true,
              meta: {
                label: 'Upload Image'
              },
              component: UploadBGImg
            },
          ]
        },
        {
          path: '/refimage',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Referral Image'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/refimage',
              name: 'List',
              component: ReferralImg
            },
            {
              path: '/refimage/create/:name',
              name: 'Create',
              component: CreateRefImg
            }
          ]
        },
        {
          path: 'dbsmcabanner',
          beforeEnter : guardMyroute,
          name: 'dbsmcabanner',
          meta: {
            label: 'DBS-MCA Banner'
          },
          component: DBSMCABanner
        },
        {
          path: '/merchant',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Merchant'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/merchant',
              name: 'Browse',
              component: ListOfMerchant
            },
            {
              path: '/merchant/create',
              name: 'Create',
              component: CreateMerchant
            },
            {
              path: '/merchant/:id',
              name: 'Detail',
              component: DetailMerchant
            },
            {
              path: '/merchant/img/default',
              name: 'Default Image',
              component: DefaultMerchantImage
            }

          ]
        },
        {
          path: '/prepaidtopup/active',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Prepaid Top-up'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/prepaidtopup/active',
              name: 'Active Cards',
              component: ActiveCards
            },
            {
              path: '/prepaidtopup/active/add',
              name: 'Add Card',
              component: AddNewCard
            },
            {
              path: '/prepaidtopup/consumed',
              name: 'Consumed',
              component: Consumed
            }
          ]
        },
        {
          path: 'useradmin',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Admin'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/useradmin',
              name: 'Users',
              component: ListOfAdmin
            },
            {
              path: '/useradmin/create',
              name: 'Create User Admin',
              component: CreateAdmin
            },
            {
              path: '/useradmin/edit/:name',
              name: 'Edit User Admin',
              component: EditAdmin
            },
            {
              path: '/useradmin/replacepasswd/:name',
              name: 'Change Password',
              component: ReplacePasswd
            },
          ]
        },
        {
          path: 'groupadmin',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Admin'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/groupadmin',
              name: 'Groups',
              component: ListOfGroups
            },
            {
              path: '/groupadmin/viewsroles/:id',
              name: 'View Roles',
              component: ViewRole
            },
          ]
        },
        {
          path: 'rolesgroup',
          beforeEnter : guardMyroute,
          name: 'Roles Group',
          meta: {
            label: 'Group'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
              {
                path: '/rolesgroup',
                name: 'Roles',
                 component: ListOfRoles
              },

          ]
        },
        {
          path: 'advertise',
          beforeEnter : guardMyroute,
          meta: {
            label: 'Advertisement'
          },
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: '/advertise',
              name: 'List',
              component: ListOfAdvertise
            },
            {
              path: '/advertise/create',
              name: 'Create',
              component: CreateAdvertise
            },
            {
              path: '/advertise/edit/:id',
              name: 'Edit',
              component: EditAdvertise
            },
            {
              path: '/advertise/image/:id',
              name: 'Image',
              component: ImageAdvertise
            },
          ]
        },
        {
          path: 'walletbalance',
          beforeEnter : guardMyroute,
          name: 'walletbalance',
          meta: {
            label: 'Wallet Balance Current'
          },
          component: Walletbalance
        },
        {
          path: 'walletbalancedaily',
          beforeEnter : guardMyroute,
          name: 'walletbalancedaily',
          meta: {
            label: 'Wallet Balance Daily'
          },
          component: Walletbalancedaily
        },
        {
          path: 'promousage',
          beforeEnter : guardMyroute,
          name: 'promousage',
          meta: {
            label: 'Promo Usage'
          },
          component: PromoUsage
        },
        {
          path: 'totalfeescollected',
          beforeEnter : guardMyroute,
          name: 'totalfeescollected',
          meta: {
            label: 'Total Fees Collected'
          },
          component: TotalFeesColected
        },
        {
          path: 'balancebytopup',
          beforeEnter : guardMyroute,
          name: 'balancebytopup',
          meta: {
            label: 'Balance By Top-up'
          },
          component: BalanceByTopup
        },
        {
          path: 'debitcredit',
          beforeEnter : guardMyroute,
          name: 'debitcredit',
          meta: {
            label: 'Debit Credit'
          },
          component: DebitCredit
        },
        {
          path: 'acclegsentries',
          beforeEnter : guardMyroute,
          name: 'acclegsentries',
          meta: {
            label: 'Account Legs Entries'
          },
          component: AccLegsEntries
        },
        {
          path: 'mcoins',
          beforeEnter : guardMyroute,
          name: 'mcoins',
          meta: {
            label: 'M-Coins'
          },
          component: MCoins
        },
        {
          path: 'userreport',
          beforeEnter : guardMyroute,
          name: 'userreport',
          meta: {
            label: 'User Report'
          },
          component: UserReport
        },
        {
          path: 'ptclogs',
          beforeEnter : guardMyroute,
          name: 'ptclogs',
          meta: {
            label: 'PTC Logs'
          },
          component: PTCLogs
        },
        {
          path: 'trsreport',
          beforeEnter : guardMyroute,
          name: 'trsreport',
          meta: {
            label: 'TRS Report'
          },
          component: TRSReport
        },

      ]
    },
    {
      path: '/auth',
      redirect: '/auth/404',
      name: 'Auth',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          //beforeEnter : guardMyroute,
          component: Login
        },
        {
          path: 'otp',
          name: 'Otp',
          //beforeEnter : guardMyroute,
          component: Otp
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'password-expiry',
          name: 'PasswordExpiry',
          component: PasswordExpiry
        }
      ]
    }
  ]
}
